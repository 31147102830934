export default {
	translation: {
		commonText: {
			start: 'PLAY',
			language: "en",
			noData: "No Data"
		},
		commonTips: {
			copySuccess: 'Copy Success~',
			copyFail: 'Copy fail~',
			saveSuccess: "Save Success~",
			saveFail: "Save Fail~",
			keyConflict: "Keys position conflict~"
		},
		header: {
			story: 'how to play',
			mode: 'about',
			nft: 'NFT',
			intro: 'whitepaper',
			casting: "mint",
			connectWallet: "CONNECT WALLET",
			logOut: "LOG OUT"
		},
		titleContent: {
			props: "Holders of the Tetriverse NFTs can participate in the 1v1 or 1v8 PVP challenge modes",
			propsLink: "https://tetriverse-white-paper.gitbook.io/tetriverse-white-paper/gameplay/prop-mode-limited-time-opening",
			battle: "It uses the classic Russian box game, i.e., 3/6 people with the screen competition. Tickets are required to participate. Tickets and tokens can be redeemable.",
			battleLink: "https://tetriverse-white-paper.gitbook.io/tetriverse-white-paper/gameplay/competitive-mode",
			classic: "Holders of the Tetriverse NFTs can participate in 1v1 or 1v8 PVP challenge mode",
			classicLink: "https://tetriverse-white-paper.gitbook.io/tetriverse-white-paper/gameplay/classic-mode",
			solo: "Tetriverse players can play the first level for free. Not dying within a certain period (300 seconds) is considered a pass, the player clearance can be on the leaderboard to get clearance rewards, and the top players' rewards are more generous;",
			soloLink: "https://tetriverse-white-paper.gitbook.io/tetriverse-white-paper/gameplay/survival-mode",
			market: "One of the key differences between Tetris and other casual games is that nearly all the assets you earn from the game belong to you. ",
			marketLink: "https://tetriverse-white-paper.gitbook.io/\nFree tickets: one for each user\nPaid tickets: they are bought with tokens and can be redeemed for TET tokens\nPrize tickets: users can receive them after winning the game and can be redeemed for TET tokens",
			ticket: "Free tickets; Paid tickets; Bonus Tickets\nFree tickets: one for each user\nPaid tickets: they are bought with tokens and can be redeemed for TET tokens\nPrize tickets: users can receive them after winning the game and can be redeemed for TET tokens",
			ticketLink: "https://tetriverse-white-paper.gitbook.io/tetriverse-white-paper/in-game-items/ticket",
			rank: "After completing each level, players will receive generous TET token rewards;\nThe first level is free, and after completing it, players can be ranked. Ranked players can share in the daily airdrop rewards.\nUsers of levels 2-10 can receive TET token rewards equal to 94% of the value of their deployed NFTs, with the remaining 6% going into the level reward pool. ",
			rankLink: "https://tetriverse-white-paper.gitbook.io/tetriverse-white-paper/gameplay/survival-mode#:~:text=1%20Orange%20%5BSSR%5D-,Reward,-Upon%20level%20completion",
			mint: "The Block Man is the central character in the Tetriverse, a type of NFT that can be cast, leveled, collected, and transacted. They are creatures who love life, pursue happiness, and bring happiness to others at the beginning of the autumn season. Their hearts are full of love - they want everyone to be friendly and happy. Each account address can collect an unlimited number of five different pictures of Block Men, and their rarity, skills, and other attributes are calculated separately. Players who acquire Block Men can play most of the game content and receive rewards.",
			mintLink: "https://tetriverse-white-paper.gitbook.io/tetriverse-white-paper/blockchain-nfts-and-tokens/block-man-nfts",
			more: "More>>"
		},
		home: {
			storyTitle: 'How to play',
			modeTitle: 'About the game',
			nftTitle: 'NFT Ecosystem',
			earn: 'Complete the lines to win ',
			stroyContent: "Tetriverse is a top-down, tile-matching game incorporating Web3 and NFT elements into the game play. \n\nEnjoy survival games and compete with one or more players to double the fun!\n",
			modeContent: "Tetriverse aims to empower people of different ages, backgrounds, and regions to play a fun and engaging game while earning a passive income. \n\nWith numerous different levels, Tetriverse is for all kinds of players and expertise, with reward opportunities at every stage.\n\nTetriverse aims to encourage all kinds of gamers to explore the Web3 space and make the most of it!",
			nftContent: "Block Men are the central NFT characters in the Tetriverse. There are five types of Block Man NFTs according to their rarity, skills, and other attributes. Each Block Man NFT grants users access to different kinds of rewards.\n\nBlock Man NFTs can be cast, collected, and traded. Each account can collect an unlimited amount of NFTs.\n\nHolding Block Men NFTs allows users to earn rewards while playing the game.",
			overview: "Overview",
			verification: "Verification",
			developing: "Developing",
			tokens: "Tokens",
			home: "Webpage",
			community: "Community",
			pawn: "Staking",
			airdrop: "Airdrops",
			event: "Events",
			marketing: "Marketing",
			appStore: "App Store",
			moreEvents: "Activities",
			more: "Growth",
			socialMediaLinks: "Social Media Links",
			reserved: "2024 Tetriverse All Rights Reserved",
			fontContent: "TETRIVERSE aims to empower people of different ages, backgrounds, and from all corners of the globe to build passive income while playing an immersive and fun elimination game. ",
			huobiExchange: "KUCOIN EXCHANGE",
			huobiActiveTime: "July 1 - July 7",
			huobiActiveContent: "VSYS trading competition, share 10,000 USDT",
			moreActive: "Enjoy more benefits",
			inviteYourFriends: "Invite your friends to earn!",
			invite: "INVITE",
		},
		invite: {
			tetriverse: "Referral Campaign",
			invitationEvent: "Invite your friends to earn!",
			copyLink: "Copy this code to invite your friends",
			step1: "Step 1",
			step1Content: "COPY THE INVITATION CODE & SEND IT TO FRIENDS",
			step2: "Step 2",
			step2Content: "YOUR FRIENDS CREATE A NEW ACCOUNT USING YOUR CODE",
			step3: "Step 3",
			step3Content: "YOU RECEIVE TET TOKENS AND OTHER REWARDS",
			invites: "Total invites",
			validInvites: "Valid invites",
			validInvitesContent: "For an invite to become valid, your friends need to log in and complete a game in the Tetriverse for at least 3 days during the event period.",
			currentValidInvitations: "CURRENT VALID INVITES: ",
			inviteContent1: "INVITE",
			inviteContent2: "PEOPLE GET",
			currentProgress: "CURRENT PROGRESS: ",
			collect: "collect",
			collected: "collected",
			claimSuccessful: "Claim successful: ",
			claimReward: "CLAIM REWARD",
			nftRewards: "NFT rewards will be distributed after the event ends. Please wait patiently.",
		},
		wallet: {
			downloadError: "Wallet Not Downloaded",
			versionError: "Current Version Outdated, Please Update to the Latest Version",
			authorizationError: "Please Authorize Access to Your Account",
			networkSettingError: "Please Switch to Mainnet to Continue",
			walletLoginError: "Wallet Not Logged In",
			fetchingAddress: "Fetching Address...",
			vsysAddressChange: "Wallet Address Mismatch",
			connectWallet: "Connect Wallet",
			pleaseInstallWallet: "Before entering the Tetris game, please install the Titan Wallet",
			downloaded: "Downloaded",
			goto: "Go to",
			walletSuccess: "Transaction Successful on the Blockchain",
			recipientAddress: "Recipient Address",
			walletNFTAction: "Please complete the transfer to NFT in your wallet",
			walletTokenAction: "Please complete the recharge in your wallet",
			actionOK: "Completed",
			walletActionUpdate: "Withdrawal request has been submitted. Please check the records in your wallet.",
			transferring: "Transferring...",
			tokenInsufficientFunds: "Token Insufficient Balance",
		},
		user: {
			registerAccount: "Sign Up",
			emailAccount: "Email",
			nickname: "Nickname (Optional)",
			invitationCode: "Invitation Code (Optional)",
			verificationCode: "Verification Code",
			getVerificationCode: "Send code",
			newPassword: "Enter your password",
			registrationAgreement: "I agree to the registration agreement",
			registrationCompleted: "Completed",
			haveAccount: "Already a member? Log In",
			sendingVerificationCode: "Sending Email Verification Code",
			sendVerificationCodeSuccess: "Email Verification Code Sent Successfully",
			sendVerificationCodeFailed: "Failed to Send Email Verification Code",
			resetingPassword: "Resetting Password",
			resetPasswordSuccess: "Password Reset Successfully",
			resetPasswordFailed: "Failed to Reset Password",
			registerSuccess: "Registered Successfully",
			registerFailed: "Registration Failed",
			passwordEmailNull: "Password, Verification Code, or Account is Empty",
			emailError: "Invalid Email Format",
			passwordError: "8 to 15 characters, including letters and numbers (no special characters)",
			passwordConfirmError: "Passwords Do Not Match",
			nicknameError: "The nickname cannot be longer than 16 characters",
			loginAccount: "Log In",
			interEmail: "Enter your email",
			interPassword: "Enter password",
			login: "Log In",
			forgotPassword: "Forgot Password",
			thirdPartyAccount: "Log In with Third-Party Account",
			register: "Register",
			loginSuccess: "Login Successfully",
			loginFailed: "Login Failed",
			loginFailed1001: "Incorrect password or account",
			login2Failed1001: "Secondary password incorrect",
			emailNull: "Email Cannot Be Empty",
			previous: "Previous",
			next: "Next",
			interCode: "Enter Verification Code",
			sendCode: "Verification Code Sent to",
			requestNew: "Request New",
			second: "Seconds",
			resetPassword: "Reset Password",
			resetPasswordSubtitle: "8-15 Characters with Numbers and Letters",
			confirmPassword: "Confirm your password",
			completed: "Complete",
		},
		responseCode: {
			// 100*
			invalidArgument: "Invalid Argument",
			requestTimeout: "Request Timeout",
			notFound: "Data Not Found",
			apiError: "API Error",
			wrongAPIKey: "API Key Error",
			// 200*
			accountAlreadyExist: "Account Already Exists",
			accountNotExist: "Account Does Not Exist",
			verificationCodeError: "Verification Code Error",
			tokenError: "Authentication Token Error",
			accountAddressNotSet: "Account Wallet Address Not Set",
			accountSecondPasswordNotSet: "Account Second Password Not Set",
			sameNFTAvatarSet: "Same NFT Avatar Already Set for User",
			emailAlreadyExist: "Account email already exists",
			nicknameAlreadyExist: "Account nickname already exists",
			invitationCodeUsed: "Account invitation code already used",
			accountBanned: "The account has been banned.",
			inviterNotExist: "The invitation code is invalid.",
			// 300*
			balanceNotEnough: "Insufficient Balance",
			notTicketOwner: "Not the Owner of the Ticket",
			ticketInvalid: "Ticket Invalid",
			freeTicketCannotRefund: "Free Ticket Cannot Be Refunded",
			notNFTOwner: "Not the Owner of the NFT",
			NFTAlreadyOnChain: "NFT Already on Chain",
			NFTLocked: "NFT Locked for Vesting",
			freeTicketAlreadyExists: "User Has Already Claimed a Free Ticket Today",
			userAlreadyHasFreeNFT: "User Has Already Claimed a Free NFT",
			NFTInMarketplace: "NFT in marketplace",
			NFTNotOnSale: "NFT not on sale",
			cannotBuyOwnNFT: "Cannot buy own NFT",
			NFTNetworkStatusError: "NFT network status error",
			NFTValidStatusError: "NFT valid status error",
			NFTMintBalanceError: "NFT minting failed: Insufficient transfer amount, funds are being refunded.",
			invalidWithdrawFeeItem: "The token withdrawal fee discount voucher is invalid.",
			wrongSecondPassword: "Secondary password incorrect",
			invitationRewardNotFound: "The invitation reward event is coming soon.",
			userInvitationRewardNotFound: "Invitation reward not found",
			userInvitationRewardFinished: "All invitation rewards have been claimed",
			userInvitationRewardInvitationNotEnough: "Invitation reward conditions not met",
			NFTInvalid: "The NFT has been activated and cannot be withdrawn",
			// 400*
			transactionNotFound: "Transaction Not Found",
			minimalTokenAmountReached: "User Input Token Amount Less Than Threshold",
			// 500*
			userAlreadyHasLockedTicket: "User Already Has a Locked Ticket in the Game",
			userHasNoValidTicket: "User Has No Available Ticket",
			userHasNoLockedTicket: "User Has No Locked Ticket",
			// 1000*
			serverInternalError: "Server Internal Error",
			vsysChainServicesOnMaintenance: "VSYS chain services on maintenance",
			// other
			otherCode: "Failed",
			networkFailed: "Network Error",
			success: "Success",
			failed: "Failed",
		},
		hall: {
			requiredOneNFT: "At least one activated NFT is required",
			joinGame: "Joining the game...",
			ranking: "Ranking",
			ticket: "Ticket",
			minting: "Mint",
			settings: "Setting",
			market: "Market",
			connectWallet: "Connect wallet",
			survivalMode: "Survival",
			itemBattle: "Items",
			multiplayerCompetition: "Competition",
			classicMode: "Classic",
			myNft: 'My Nft',
			settings: 'Settings',
			tickets: 'Tickets',
			security: 'Security',
			income: 'Income',
			dynamic: 'Dynamic',
			walletAssets: 'Wallet Assets',
			gameTokens: 'Game Tokens',
			startingAfterwards: "Start in",
			endingAfterwards: "Ends in",
			gameEnded: "This round of the game has ended, please come back next time.",
			topup: "TOP UP",
			inActivatedErr: "Your NFT is not activated",
			activate: "activate",
		},
		gameChooseModel: {
			points: "Points",
			ranking: "Ranking",
			itemModel: "Item",
			competitiveModel: "Competitive",
			classicModel: "Classic",
			oneVS1: "Random 1v1",
			oneVSN: "Multiplayer Game",
			friendsVS: "Friends Battle",
			oneVS8: "8VS8",
			createRoom: "Create Room",
			roomList: "Room List",
			inviteFriendsContent: "Copy the invitation link and share it with friends to play together",
			copy: "Copy",
			matching: "Matching",
			close: "Cancel",
			username: "Username",
			room: "'s Room",
			waiting: "Waiting",
			spectator: "Spectator",
			spectating: "Watch game",
			exit: "Exit Room",
			previous: "Previous",
			next: "Next",
			quickJoin: "Quick Join",
			join: "Join Room",
			back: "Back",
			refresh: "Refresh",
			joinRoomError: "The room has expired, failed to join the room.",
			quickJoinError: "There are no rooms available that meet the criteria currently.",
			startGame: "Start game",
			locked: "Locked",
			homeowner: "Homeowner",
			threePlayerBattle: "Three-player battle",
			sixPlayerBattle: "Six-player battle",
			insufficientRemainingTickets: "Insufficient remaining tickets",
			invitationLinkInvalid: "The invitation link is invalid, please check.",
			roomFullJoinFailed: "Room is full, joining failed.",
			pleaseCopyInvitationLinkFirst: "Please copy the invitation link first.",
			generatingLink: "Generating link...",
			adTitle: "Watch the ad to earn TET!",
			adDownload: "DOWNLOAD",
			adLoadMore: "WATCH THE AD"
		},
		soundSettings: {
			setting: "Setting",
			music: "Music",
			soundEffects: "Effect",
			specialEffects: "Special Effects",
			diaposalReminder: "Block Preview",
			language: "Language",
			keyPosition: "Keys Position",
			off: "Off",
			on: "On",
			prompt: "Prompt",
			save: "Save",
			cancel: "Cancel",
			left: "left",
			right: "right",
			rotateR: "rotate-right",
			rotateL: "rotate-left",
			push: "push",
			momentum: "momentum",
			freeze: "Frozen",
			add: "Bricking",
			acc: "acceleration"
		},
		mint: {
			NFTBlindBox: "NFT mystery box",
			startForging: "Mint",
			freeGiftBox: "Mint your free NFT",
			forging: "Buy with",
			haveReceived: "Redeemed",
			claimNow: "Claim Now",
			rarity: "Rarity",
			confirm: "Confirm",
			tryAgain: "Try Again",
			tenMoreTimes: "Ten more times",
			mintingNFT: "Mint NFT",
			success: "Success：",
			failed: "Failed：",
			minting: "Minting：",
			refunding: "Refuning",
			onclick: "Onclick",
			passable: "Passable",
			viewTheMintedNFT: "view the minted NFT",
			transferNFT: "transfer NFT",
			goToIncomeRecords: "【Personal Center-Details】",
			goToTransferInNft: "【Personal Center-transfer NFTs】",
			goToMyNft: "【Personal Center-My NFT】",
			checkFailure: "Check the reason for the failure",
			genesisNFT: "GENESIS NFT",
			comingSoon: "COMING SOON：",
			price: "PRICE",
			limitedEdition: " % off for\na limited time,\nlimited quantity sale",
			link: "LINK",
			enjoy: "enjoy",
			discount: "discount",
			buy: "Buy",
			priceInfo1: 'Maximum slippage of 2%. If the price changes between placing the order and the order confirmation period, this is referred to as "slippage."',
			priceInfo2: 'If the slippage exceeds the "maximum slippage" setting, your minting will be automatically canceled.',
			mintingVSYSInfo: "Please wait, then you can view the minted NFTs via [Personal Center - Transfer NFT] in the game.",
			mintingVSYSSuccess: "NFT minting successful. You can view the minted NFTs via [Personal Center - Transfer NFT] in the game.",
			mintingVSYSFailed: "NFT minting failed. If you have any questions, please contact the community staff.",
			mintingVSYSRefunding: "NFT minting failed, funds are being refunded",
			mintingVSYSRefunded: "NFT minting failed. Funds have been refunded to the original wallet address. Please check.",
			pleaseConnectWallet: "Please connect your wallet first.",
			insufficientVSYSBalance: "Insufficient VSYS balance.",
			originalPrice: "The amount over 3000 at original price.",
			pcs: "pcs",
			left: " left",
			limited: "LIMITED",
			expiresIn: "EXPIRES IN：",
			tradingSetup: "Trading set up",
			customize: "Customize%",
			slippageLimit: "Slippage Limit",
			slippageLimitLower: "The value is too low (2%), which may cause the exchange to fail.",
			slippageLimitUpper: "The entered slippage (20%) is considered very high, which may result in a worse exchange rate.",
			buyVsys: "BUY VSYS"
		},
		ticket: {
			ticket: "Admission Ticket",
			purchaseSuccessful: "Purchase Successful",
			purchaseUnsuccessful: "Purchase Unsuccessful",
			claimedSuccessful: "Successfully Claimed",
			haveClaimedNextCome: "You have claimed it, please come back next time",
			competitiveTickets: "Paid tickets",
			buyNow: "Purchase",
			freeTicket: "Upgrading",
			freeClaim: "Claim free",
			haveClaimed: "Claimed",
			confirmTicket: "Confirm ticket purchase",
			confirmTicektContent1: "Are you sure you want to spend",
			confirmTicektContent2: "TET to buy",
			confirmTicektContent3: "tickets?",
		},
		personalCenter: {
			personalCenter: "Personal Center",
			survivalMode: "Survival",
			classicBattle1v1: "Classic (1V1)",
			itemMode1v1: "Item (1V1)",
			classiceBattleMulti: "Classic (Multi)",
			itemModeMulti: "Item (Multi)",
			tokenRecharge: "Deposit tokens",
			transferNFT: "Transfer NFTs",
			tokenWithdrawal: "Withdraw tokens",
			withDrawNFT: "Withdraw NFTs",
			myNFT: "My NFT",
			myTickets: "My Tickets",
			incomeRecords: "Details",
			safeSettings: "Security Setting",
			availableToken: "TET Balance",
			refresh: "Refresh",
			setAvatar: "Set as Avatar",
			removeAvatar: "Remove Avatar",
			loadMore: "Load More",
			secondaryPassword: "Level 2 Password",
			requiredSetPassword: "Password to Withdraw tokens and NFTs",
			reset: "Reset",
			set: "Set",
			setSuccess: "Set successfully",
			resetSecondaryPassword: "Reset Level 2 Password",
			setSecondaryPassword: "Set Level 2 Password",
			pleaseEnterSixNumerical: "Please enter a six-digit numerical password",
			pleaseEnterSecondaryPassword: "Enter your password",
			withdrawNFTSuccess: "Withdrawal successful",
			secondaryPasswordNone: "Please check if the secondary password is entered",
			pleaseEnterValue: "Please enter an amount",
			withdrawalAll: "Withdraw All",
			confirmRefundTitle: "Confirm Exchange",
			refundSuccess: "Exchange successful. You can check the records in the transaction history.",
			refundFailed: "Exchange failed.",
			marketAction: "Market Action",
			OnChain: "On chain",
			InGame: "In game",
			InMarket: "In Market",
			All: "All",
			InActivated: "Inactivated",
			Valid: "Valid",
			Expired: "Expired",
			tokenInsufficientFunds: "Please check if the wallet address is correct, the current account's Token balance is 0.",
			tokenInsufficientError: "Error, no sufficient balance",
			select: "select",
			enterAmount: "or enter amount",
			inviteCashback: "Invite Cashback",
			inviteTitle: "Invite friends!\nThe referrer will receive a cashback voucher",
			inviteSubtitle: "View invitation rules",
			inviteRules: "Share the invitation voucher code/link with friends who haven't registered for a Tetris account yet.\n\nRoutine Task: Invitees must register using the specified invitation code/invitation link. For every 5 people invited, the inviter will receive a cashback voucher reward.",
			invitationCode: "Invitation voucher code",
			invitationLink: "invitation voucher link",
			inviteStep1: "First step",
			inviteStep1Content: "Share your invitation link",
			inviteStep2: "Second step",
			inviteStep2Content: "Invite friends to register via",
			inviteStep3: "Third step",
			inviteStep3Content: "For every 5 people invited\nyou will receive cashback voucher",
			overview: "Overview",
			invitedTotal: "Total invitees",
			availableInvited: "Unredeemed invitees",
			cashTotal: "Total cashback voucher",
			availableCash: "Available cashback voucher",
			cashWithdrawCoupon: "Cash withdraw coupon",
			cashbackVouchersSelected: " cashback vouchers selected, ",
			feeWaived: " % fee waived",
			noCashbackVoucherSelected: "No cashback voucher selected, up to 14% fee waived",
			lockedAsset: "Locked asset: ",
			withdrawFee: "Withdraw fee",
			threshold: "Threshold",
			fee: "Fee",
			cash: "Cash",
			discount: "Discount",
			obtainCoupons: "Obtain coupons",
			luckyDraw: "Lucky draw",
			rules: "Rules",
			rulesContent: "Event duration: Ongoing. These rules apply only to users participating through this page;\n\nDuring the event, spending 100 TET gives a chance to win token rewards worth up to 9999 TET;\n\n50 TET chance: 20.00%\n66 TET chance: 20.00%\n88 TET chance: 10.00%\n100 TET chance: 20.00%\n128 TET chance: 20.00%\n888 TET chance: 5.00%\n999 TET chance: 4.90%\n9999 TET chance: 0.10%\n\nIf you engage in any improper means, violate event rules or laws and regulations, the platform reserves the right to unilaterally cancel your participation and winning qualifications. The platform has the final interpretation right of the event.",
			activityRules: "Activity rules",
			winingRecords: "Wining records",
			congratulations: "Congratulations",
			congratulationsSubtitle: "The prize has been placed in balance,you can go to check",
			checkBalance: "check balance",
			startDraw: "Start draw\n(- 100TET)",
			listEnd: "It is all, nothing more 🤐",
			reviewing: 'System under review, please wait.',
		},
		tableData: {
			ordinal: "Ordinal",
			UID: "Nickname",
			challenge: "Challenge",
			ability: "Ability value",
			score: "Score",
			highestWinStreak: "Top streak",
			wins: "Win(s)",
			losses: "Loss(s)",
			points: "Points",
			highestScore: "Highest score",
			MVPs: "MVP(s)",
			time: "Time",
			amount: "Quantity",
			type: "Type",
			status: "Status",
			recipient: "Recipient",
			expiredAt: "Expiration Date",
			price: "Value",
			recipientType: "Acquisition",
			action: "Action",
			permanent: "Permanent Valid",
			all: "All",
			free: "Claim",
			paid: "Purchase",
			earned: "Rewards",
			valid: "Valid",
			expired: "Expired",
			lockedInRoom: "Locked",
			used: "Used",
			refund: "Exchange",
			airdrop: "Airdrop",
			unrefund: "Non-Exchangeable",
			refunded: "Refunded",
			minting: "Minting",
			pending: "Pending",
			locked: "Locked",
			success: "Success",
			failed: "Failed",
			allTicket: "Tickets",
			freeTicket: "Free Ticket(s)",
			paidTicket: "Paid Ticket(s)",
			buyTicket: "Purchase Ticket(s)",
			refundTicket: "Refund Ticket(s)",
			useTicket: "Use Ticket(s)",
			earnedTicket: "Reward Ticket(s)",
			depositToken: "Recharge Token",
			withdrawToken: "Withdraw Token",
			reward: "Rewards",
			mintNFT: "Forge NFT",
			freeNFT: "Claim Free NFT",
			depositNFT: "Transfer to NFT",
			withdrawNFT: "Withdraw NFT",
			list: "List NFT",
			remove: "Remove NFT",
			sell: "Sell NFT",
			buy: "Buy NFT",
			exchangeFee: "Exchange fee: 20%",
			SpinWheel: "Spin Wheel",
			InvitationReward: "Invitation Reward",
			SpinWheeled: "WON",
			Winners: "Winners",
			season: "Season",
			rank: "rank",
			"Classic 1v1": "Classic 1v1",
			"Classic Multi": "Classic Multi",
			"Props 1v1": "Props 1v1",
			"Props Multi": "Props Multi",
			review: "Review",
		},
		game: {
			chooseHero: "Choose Your Hero",
			quickSetup: "Automatic",
			initialize: "Reset",
			abilityDemand: "Ability demand",
			ability: "Ability",
			play: "Start",
			level: "Level",
			freeLevels: "Free Levels",
			premiumLevels: "Premium Levels (More Rewards)",
			comingSoon: "Coming Soon",
			enemyTerritory: "Enemy territory",
			freezeCountdown: "Freeze countdown",
			frozenCard: "Frozen Card",
			brickingCard: "Bricking Card",
			accelerationCard: "Acceleration Card",
			spectating: "Spectating",
			itemDropRate: "Item drop rate",
			points: "Points",
			pointss: "Points",
			countdownTimer: "Timer",
			currentState: "State",
			alive: "Alive",
			roomList: "Room List",
			title: "Home",
			backTitle: "Back Home",
			restart: "Restart",
			backGame: "Back Game",
			keepWatching: "Keep Watching",
			challengeCleared: "Challenge Cleared",
			challengeFailed: "Challenge Failed",
			competitionCompleted: "Competition completed",
			gamePaused: "Game paused",
			gamePausedContent: "Click 'Back Game' to proceed.",
			challengeFailedContent: "Challenge Failed",
			soloScoreFail: "This round is invalid, scores below 400 will not be ranked on the leaderboard. Please enjoy the game.",
			challengeClearedContent1: "Congratulations!The Everyday Airdrop Rewards will be received within ",
			challengeClearedContent2: " , please check your assets at that time.",
			confirm: "Confirm",
			ticket: "ticket",
			tip1: "No. 1",
			tip2: "No. 2",
			tip3: "No. 3",
			tip4: "No. 4",
			tip5: "No. 5",
			tip6: "No. 6",
			tip7: "No. 7",
			tip8: "No. 8",
			win: "Win",
			defeat: "Defeat",
			gameinvalid: "The match is abnormal, and the record is invalid.",
			networkDisconnectedGameOver: "Network disconnected, the game is over.",
			networkError: "Your network is unstable, please pay attention to the internet connection.",
			copySuccess: "Copied successfully, please go to Discord to share.",
			isAdTitle: "Click the button below to claim your score and receive the rewards for this round. If not claimed, it will be considered as giving up this round's points.",
			isAdButton: "Claim Score",
			isAdedButton: "Score claimed successfully"
		},
		market: {
			//  market list
			refresh: "refresh",
			results: "results",
			rarity: "Rarity",
			activateStatus: "Activate Status",
			validityPeriod: "validity period",
			validityPeriodError: "The minimum value must be less than the maximum value",
			sell: "I want to sell",
			loadMore: "Load more",
			"price ASC": "price from low to high",
			"price DESC": "price from high to low",
			"quality ASC": "quality: low to high",
			"quality DESC": "quality: high to low",
			"time ASC": "oldest",
			"time DESC": "recent",
			tetrisSeries: "Tetris series",
			buy: "Buy",
			restartPeriod: "Restart period",
			// detail
			nftValidityPeriod: "NFT Validity period:",
			listingEndsAt: "Listing ends at:",
			buyNow: "Buy now",
			remove: "Remove",
			sellTitle: "Set price to sell",
			activation: "Activation",
			expired: "Expired",
			contract: "Contract",
			tokenId: "Token ID",
			blockchain: "Blockchain",
			token: "Token",
			royalties: "Royalties",
			info: "INFO",
			NFTpowerboost: "Power level",
			NFTitemdroprateincrease: "Item drop rate",
			// buy
			price: "Price",
			paymentCurency: "Payment curency",
			balance: "Balance",
			cancel: "cancel",
			insufficientFunds: "Insufficient funds",
			handlingFee: "Handling fee",
			handlingFeeContent: "Listing is free. Once sold, the following fees will be decked from the payment",
			transactionFee: "Transaction fee",
			creatorFee: "Tips for creator",
			list: "Sell",
			expiration: "Expiration",
			hour1: "1 Hour",
			hour3: "3 Hours",
			hour6: "6 Hours",
			hour168: "7 Days",
			hour336: "14 Days",
			hour720: "30 Days",
			listButton: "Put on shelf",
			confirm: "confirm",
			listSuccess: "Listing successful",
			listFiled: "Listing failed",
			pleaseEnterPrice: "Please enter price",
			buySuccess: "Purchase successful",
			buyFiled: "Purchase failed",
			listInBulk: "List in bulk",
			activate: "Activate",
			activated: "Activated",
			transferOut: "Transfer out",
			remove: "Remove",
			extract: "Extract",
			listExpiration: "List expiration",
			productAtPrice: "List the product at price of ",
			willEar: "and ear ",
			activateNftTitle: "Confirm Activation",
			activateNftContent1: "Once activated, this NFT will have a lifespan of 60 days,",
			activateNftContent2: "Activation is irreversible, please proceed with caution",
			activateNftSuccess: "Activation Successful",
			activateNftFailed: "Activation Failed",
			activateNftFailedText: "The NFT is already listed and cannot be activated",
			removeNftTitle: "Confirm Removal",
			removeNftSuccess: "Successfully Removed",
			removeNftFailed: "Removal Failed",
		}
	}
}